import React from 'react';
import Group from "./Group";

const Status = ({status, groups, sizes}) => {
    return (
        <>
            {Object.keys(groups).sort().map((key) => {
                return (
                    <Group key={`${status}-${key}`} name={key} moves={groups[key]} sizes={sizes} />
                );
            })}
        </>
    )
}

export default Status;
