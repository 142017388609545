import React from 'react';
import { format, formatDistanceToNow, formatRelative } from 'date-fns';

export default ({move, sizes}) => {
    let bottomText = [];

    move.trailer_size && bottomText.push(`${move.trailer_size}'`);
    move.temp && bottomText.push(`${move.temp}°`);
    move.location && bottomText.push(move.location);
    move.cutoff && bottomText.push(move.cutoff);

    bottomText = bottomText.join(' - ');

    let height = sizes.cardHeight;

    let groundingTime, relativeGroundingDate, eventTime, relativeEventDate, isGroundingToday, isGroundingSoon;

    if (move.moveLocation) {
        height = sizes.cardHeight + 65;
    }

    if (move.status === 'GROUNDED' && move.grounded_time) {
        height = sizes.cardHeight + 40;
    }

    if (move.status === 'GROUNDED' && move.pickup_number) {
	height = sizes.cardHeight + 40;
    }

    if (move.grounded_time) {
        height = sizes.cardHeight + 40;
    }

    if (move.moveLocation && move.moveLocation.estimated_grounding_time) {
        groundingTime = new Date(move.moveLocation.estimated_grounding_time);
        relativeGroundingDate = formatRelative(groundingTime, new Date());

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const groundingDate = new Date(groundingTime.getFullYear(), groundingTime.getMonth(), groundingTime.getDate());

        const differenceInHours = (groundingTime - now) / 1000 / 60 / 60;

        isGroundingSoon = differenceInHours <= 24;
        isGroundingToday = groundingDate.getTime() === today.getTime();

        eventTime = new Date(move.moveLocation.event_timestamp);
        relativeEventDate = formatDistanceToNow(eventTime, { addSuffix: true });
    }

    if (move.grounded_time) {
        groundingTime = new Date(move.grounded_time);
        relativeGroundingDate = formatDistanceToNow(groundingTime, { addSuffix: true });
    }

    return (
        <div
            className={`move ${move.type}`}
            style={{
                width: sizes.cardWidth,
                height
            }}
            onClick={() => {
                window.postMessage({ action: 'FETCH_MOVE', id: move.id }, '*')
            }}
        >
            <div className={`priority p${move.priority}`}>{" "}</div>

            <div className="content">
                <div
                    className="trailer"
                    style={{
                        fontSize: sizes.trailerFontSize
                    }}
                >
                    {move.trailer}
                </div>

                <div className="icons">
                    {move.is_ups &&
                        <img alt="UPS" height={sizes.iconHeight} src="/ups.png" />
                    }
                    {move.is_haz &&
                        <img alt="HAZ" height={sizes.iconHeight} src="/haz.png" />
                    }
                    {move.is_bad &&
                        <img alt="RED TAG" height={sizes.iconHeight} src="/redtag.png" />
                    }
                </div>

                <div
                    className="toFrom"
                    style={{
                        fontSize: sizes.toFromValuesFontSize,
                        width: sizes.toFromWidth
                    }}
                >
                    <table>
                        <tbody>
                            <tr>
                                <td className="text" style={{fontSize: sizes.toFromTextFontSize}}>TO</td>
                                <td className="values">{move.to}</td>
                            </tr>
                            <tr>
                                <td className="text" style={{fontSize: sizes.toFromTextFontSize}}>FROM</td>
                                <td className="values">{move.from}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    className="bottomRow"
                    style={{
                        fontSize: sizes.bottomRowFontSize
                    }}
                >
                    {move.status === 'RECEIVED' && move.moveLocation && 
                        <>
                            <div title={eventTime}>
                                {move.moveLocation.last_event} at {move.moveLocation.event_city}, {move.moveLocation.event_state} {relativeEventDate}
                            </div>
                            {move.moveLocation.estimated_grounding_time &&
                                <div title={groundingTime} className={isGroundingSoon ? 'groundingToday' : ''}>
                                    Grounding {relativeGroundingDate}
                                </div>
                            }
                        </>
                    }

                    {move.status === 'GROUNDED' && move.grounded_time &&
                        <div>
                            Grounded {relativeGroundingDate}
                        </div>
                    }

                    {bottomText}

	            {move.status === "GROUNDED" && move.pickup_number && 
                        <div>
                            Pickup Number: {move.pickup_number}
			</div>
                    }

                    {move.status === 'ASSIGNED' && move.ingate_time && (
                        // eslint-disable-next-line jsx-a11y/accessible-emoji
                        <div>
                            ✔️ Ingated at {format(new Date(move.ingate_time), 'hh:mm a')}
                        </div>
                    )}
                </div>

                
            </div>
        </div>
    )
}
