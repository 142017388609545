import React, { useEffect, useState } from 'react';
import autobahn from 'autobahn';
import Dashboard from "./TVView";

import './App.css';
import UserView from "./UserView";
import TVView from "./TVView";

export default () => {
  const [moves, setMoves] = useState({});

  useEffect(() => {
    // Watch for move changes
    const connection = new autobahn.Connection({
      transports: [{
        type: 'websocket',
        url: `wss://api.centeratransport.com/socket`
      }],
      realm: 'realm'
    });
    connection.onopen = function (session) {
      session.subscribe('moves', (args) => {
        // Merge new moves with current moves
        setMoves((moves) => {
          moves = {...moves};

          // Convert array of moves into object
          const newMoves = args.reduce((arr, value) => {
            // Filter out delivered
            if (value.status === 'DELIVERED') {
              delete moves[value.id];
              return arr;
            }

            // Filter out canceled
            if (value.status === 'CANCELED') {
              delete moves[value.id];
              return arr;
            }

            arr[value.id] = value;
            return arr;
          }, {});

          return {...moves, ...newMoves};
        });
      });
    };
    connection.open();

    // Get initial payload
    fetch('https://api.centeratransport.com/search/moves', {
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    })
      .then(res => res.json())
      .then((moves) => {
        // moves = moves.message.map((move) => ({[move.id]: move }));
        // moves = Object.assign({}, ...moves );

        moves = moves.message.reduce((arr, value) => {
          // Filter out delivered
          if (value.status === 'DELIVERED') {
            delete moves[value.id];
            return arr;
          }

          // Filter out canceled
          if (value.status === 'CANCELED') {
            delete moves[value.id];
            return arr;
          }

          arr[value.id] = value;
          return arr;
        }, {});

        setMoves(moves);
      });
  }, []);
  if (window.location.search.indexOf("?screen") !== -1) {
    return (
        <TVView moves={moves} />
    );
  } else {
    return (
        <UserView moves={moves}/>
    );
  }
}
