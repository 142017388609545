import React, { useEffect, useState } from 'react';
import './App.css';
import Move from "./Move";
import moment from "moment";
import {sortBy, debounce} from 'lodash';
import debounceRender from 'react-debounce-render';

const TVView = ({moves}) => {
    console.log("Rendering Dashboard", Object.keys(moves).length);
    window.moves = moves;
    console.profile();

    let isTV = false;
    // Replace this with a proper query string reading
    if (window.location.search === "?screen=1") {
        isTV = true;
        document.body.className = 'tv';
        document.documentElement.scrollLeft = 0;
    } else if (window.location.search === "?screen=2") {
        isTV = true;
        document.body.className = 'tv';
        //document.documentElement.scrollLeft = 3840;
	document.documentElement.scrollLeft = window.innerWidth;
    } else if (window.location.search === "?screen=3") {
        isTV = true;
        document.body.className = 'tv';
        //document.documentElement.scrollLeft = 7680;
	document.documentElement.scrollLeft = window.innerWidth * 2;
    }

    let isMobile = false;

    if (window.innerWidth < 1000) {
        isMobile = true;
        document.body.className = 'mobile';
    }

    // Variable sizes for everything
    // @todo Put this in it's own file and import where needed
    let scale = 1.5;

    if (isTV) {
        scale = 1.2;
    }

    const sizes = {
        headerFontSize: 20 * scale,
        trailerFontSize: 16 * scale,
        toFromValuesFontSize: 12 * scale,
        toFromWidth: 105 * scale,
        cardHeight: 30 * scale,
        cardWidth: 250 * scale,
        iconHeight: 20 * scale,
        bottomRowFontSize: 10 * scale,
        toFromTextFontSize: 8 * scale
    }

    if (isTV) {
        sizes.trailerFontSize = 18 * scale;
        sizes.toFromValuesFontSize = 14 * scale;
        sizes.bottomRowFontSize = 12 * scale;
    }

    // Set priorities
    // @todo This needs to be a shared util file because it is used in dashboard and in internal
    Object.keys(moves).forEach((i) => {
        if (!moves[i].priority) {
            moves[i].priority = 4;
            moves[i].priorityAutomatic = true;

            if (moves[i].is_ups) {
                moves[i].priority = 1;
            }

            if (moves[i].grounded_time) {
                let diff = moment().diff(
                    moment(moves[i].grounded_time),
                    'hours'
                );

                if (diff >= 36) {
                    moves[i].priority = 1;
                } else if (diff >= 24) {
                    moves[i].priority = 2;
                } else if (diff >= 12) {
                    moves[i].priority = 3;
                }
            }

            if (moves[i].is_bad) {
                moves[i].priority = 4;
            }
        }
    })

    // Group by status
    let m = Object.keys(moves).reduce((acc, curr) => {
        let move = moves[curr];
        if(!acc[move.status]) acc[move.status] = [];
        acc[move.status].push(move);
        return acc;
    }, {});

    // Group status groups by customer/ramp/driver
    if (m.RECEIVED) {
        m.RECEIVED = Object.keys(m.RECEIVED).reduce((acc, curr) => {
            let move = m.RECEIVED[curr];
            if(!acc[move.shipper]) acc[move.shipper] = [];
            acc[move.shipper].push(move);
            return acc;
        },{});
    }

    if (m.GROUNDED) {
        m.GROUNDED = Object.keys(m.GROUNDED).reduce((acc, curr) => {
            let move = m.GROUNDED[curr];
            if(!acc[move.from]) acc[move.from] = [];
            acc[move.from].push(move);
            return acc;
        },{});
    }

    if (m.ASSIGNED) {
        m.ASSIGNED = Object.keys(m.ASSIGNED).reduce((acc, curr) => {
            let move = m.ASSIGNED[curr];
            let driver = (move.driver && move.driver.nick_name) || 'None';
            if(!acc[driver]) acc[driver] = [];
            acc[driver].push(move);
            return acc;
        },{});
    }

    window.m=m;

    let shownMoves = [];

    let vh = window.innerHeight;
    let fullCardHeight = sizes.cardHeight + 12;
    let maxCards = Math.floor(vh / fullCardHeight);

    if (isTV) {
        maxCards = 45;
    }

    let currentRow = 1;

    let rules = {
        RECEIVED: ['priority', 'from'],
        ARRIVED: ['priority', 'from'],
        GROUNDED: ['priority', 'grounded_time', 'id'],
        ASSIGNED: ['assigned_time'],
        DELIVERED: ['id']
    }

    // Sort all of the groups and sub-groups
    Object.keys(m).forEach((status) => {
        if (!Array.isArray(m[status])) {
            Object.keys(m[status]).forEach((header) => {
                m[status][header] = sortBy(m[status][header], rules[status]);

                if (header !== "ASSIGNED") {
                    m[status][header] = sortBy(m[status][header], (o) => !o.is_bad);
                }
            });
        }
    });

    // Align everything into proper columns
    let addRow = ({component, header, empty}) => {
        if (component) {
            if (currentRow === 1) {
                addRow({header: header});
            }

            shownMoves.push(component);
        } else if (header) {
            if (currentRow === maxCards) {
                addRow({empty: true});
            }

            shownMoves.push(
                <div
                    className="groupName"
                    style={{
                        fontSize: sizes.headerFontSize,
                        width: sizes.cardWidth,
                        height: sizes.cardHeight,
                    }}
                >
                    {header}
                </div>
            );
        } else if (empty) {
            if (currentRow > 1) {
                shownMoves.push(<div className="emptyMove" style={{height: sizes.cardHeight}} />);
            } else {
                return;
            }
        }

        currentRow++;

        if (currentRow > maxCards && !isMobile) {
            currentRow = 1;
        }
    }

    let addGroup = (group) => {
        currentRow = 1;

        Object.keys(group).sort().forEach((header) => {
            let moves = group[header];

            addRow({header: header});

            Object.keys(moves).forEach((id) => {
                addRow({
                    component: <Move key={id} move={moves[id]} sizes={sizes} />,
                    header: header
                });
            });

            addRow({empty: true});
        });
    }

    m.RECEIVED && addGroup(m.RECEIVED);

    if (m.ARRIVED) {
        addRow({header: "ARRIVED"});

        m.ARRIVED.forEach((move) => {
            addRow({
                component: <Move key={move.id} move={move} sizes={sizes} />,
                header: "ARRIVED"
            })
        })
    }

    shownMoves.push(<div className="statusDivider"></div>);

    m.GROUNDED && addGroup(m.GROUNDED);

    shownMoves.push(<div className="statusDivider"></div>);

    m.ASSIGNED && addGroup(m.ASSIGNED);

    console.profileEnd();

    return (
        <div>
            {shownMoves.map((component, index) =>
                <React.Fragment key={index}>
                    {component}
                </React.Fragment>
            )}
        </div>
    );
};

export default debounceRender(TVView, 5000, {
    'leading': false,
    'trailing': true
});
