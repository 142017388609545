import React from 'react';
import Move from "./Move";

const Group = ({name, moves, sizes}) => {
    if (!moves.length) {
        return (<></>);
    }

    return (
        <div>
            <div
                className="groupName"
                style={{
                    fontSize: sizes.headerFontSize,
                    width: sizes.cardWidth,
                    height: sizes.cardHeight,
                }}
            >
                {name}
            </div>

            {moves.map((move) => (
                <Move key={move.id} move={move} sizes={sizes} />
            ))}

            <div className="emptyMove" style={{height: sizes.cardHeight}} />
        </div>
    );
}

export default Group;
